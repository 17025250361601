const STATES = {
    loading: ' loading-page',
    waiting: ' waiting'
};

const PageLoading = props => {
    const wrap = (
        <div className="wrap">
            <div className="wall wall-right"></div>
            <div className="wall wall-left"></div>   
            <div className="wall wall-top"></div>
            <div className="wall wall-bottom"></div>   
            <div className="wall wall-back"></div>    
        </div>
    );
    const cls = `loader${props.status ? STATES[props.status] : ''}`;

    return (
        <div className={cls}>
            <div className="scene">
                {wrap}
                {wrap}
            </div>
        </div>
    );
};

export default PageLoading;