import React from "react";

const DataContext = React.createContext({
    pages: [],
    lab: [],
    projects: [],
    ui: {
        loading: ''
    },
    setData: () => {}
});

export default DataContext;