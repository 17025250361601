import { useCallback } from 'react';
// import THREE JS;
const THREE = require('three/build/three.module');

const useThreeJS = () => {
    const init = useCallback(() => {
        // THREE JS
        let scene = new THREE.Scene();
        let camera = new THREE.PerspectiveCamera( 50, window.innerWidth/window.innerHeight, 0.1, 1000 );
        let renderer = new THREE.WebGLRenderer();
        renderer.setSize( window.innerWidth, window.innerHeight );
        document.getElementById('outerspace').appendChild( renderer.domElement );

        let loader = new THREE.TextureLoader().load(
            // resource URL
            '/assets/images/star.png',
            // Function when resource is loaded
            function ( texture ) {
                // in this example we create the material when the texture is loaded
                // let material = new THREE.MeshBasicMaterial( {
                //     map: texture
                // } );
            },
            // Function called when download progresses
            function ( xhr ) {
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // Function called when download errors
            function ( xhr ) {
                console.error( 'An error happened' );
            }
        );

        // create the particle variables
        let particleCount = 1800,
            particles = new THREE.BufferGeometry(),
            pMaterial = new THREE.PointsMaterial({
                color: 0xFFFFFF,
                size: 2,
                map: loader,
                blending:THREE.AdditiveBlending,
                transparent:true
            }),
            positions = [];

        // now create the individual particles
        for (let p = 0; p < particleCount; p++) {

            // create a particle with random
            // position values, -250 -> 250
            let pX = Math.random() * 500 - 250,
                pY = Math.random() * 500 - 250,
                pZ = Math.random() * 500 - 250;
                // particle = new THREE.Vector3(pX, pY, pZ);

            // add it to the geometry
            positions.push(pX, pY, pZ);
        }

        particles.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );

        // create the particle system
        let particleSystem = new THREE.Points(
            particles,
            pMaterial);

        // add it to the scene
        scene.add(particleSystem);

        camera.position.z = 0;

        let render = function () {
            requestAnimationFrame( render );

            if(camera.position.z < 500)
                camera.position.z += .05;
            else
                camera.position.z = 0;
            //camera.position.x += .05;
            //camera.rotation.x += .001;
            particleSystem.rotation.y += 0.02 * Math.PI / 180;
            particleSystem.rotation.z += 0.01 * Math.PI / 180;
            particleSystem.rotation.x += 0.015 * Math.PI / 180;

            renderer.render(scene, camera);
        };

        render();
    }, []);

    return { init };
};

export default useThreeJS;