import React, { useEffect, Suspense, useCallback, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import DataContext from "./store/data-context";
import useHttp from "./hooks/useHttp";
import useThreeJS from "./hooks/useThreeJS";
import PageLoading from "./components/UI/PageLoading";

// PAGES
const Home = React.lazy(() => import('./pages/Home'));
const Projects = React.lazy(() => import('./pages/Projects'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Lab = React.lazy(() => import('./pages/Lab'));
const LabDetail = React.lazy(() => import('./pages/LabDetail'));

const App = props => {
    const { init: threeJSInit } = useThreeJS();
    const { sendRequest } = useHttp();
    const { setData, UI } = useContext(DataContext);
    const setProjectsData = useCallback(data => {
        setData(data);
    }, [setData]);

    useEffect(() => {
        threeJSInit();
        sendRequest(setProjectsData);
    }, [threeJSInit, sendRequest, setProjectsData]);

    return (
        <React.Fragment>
            <Suspense fallback={<PageLoading status="waiting" />}>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/lab" element={<Lab />} />
                    <Route path="/lab/*" element={<LabDetail />} />
                    <Route path="/*" element={<NotFound />} />
                </Routes>
            </Suspense>
            <PageLoading status={UI.loading} />
        </React.Fragment>
    );
};

export default App;