import { useReducer, useCallback } from "react";
import DataContext from "./data-context";

const INITIAL_STATE = {
    pages: [],
    lab: [],
    projects: [],
    UI: {
        loading: ''
    }
};

const siteReducer = (state, action) => {
    if(action.type === 'SET_DATA'){
        return { ...state, pages: action.value.pages, projects: action.value.projects, lab: action.value.lab};
    }

    if(action.type === 'LOADING'){
        const newUI = { ...state.UI };
        newUI.loading = action.value;

        return { ...state, UI: newUI };
    }

    return INITIAL_STATE;
};

const DataProvider = (props) => {
    const [siteStates, dispatchSiteStates] = useReducer(siteReducer, INITIAL_STATE);
    const setDataHandler = useCallback(data => {
        dispatchSiteStates({type: 'SET_DATA', value: data});
    }, [dispatchSiteStates]);

    const loadingHandler = val => {
        dispatchSiteStates({type: 'LOADING', value: val});
    };

    const dataCtx = {
        pages: siteStates.pages,
        lab: siteStates.lab,
        projects: siteStates.projects,
        UI: siteStates.UI,
        setData: setDataHandler,
        setLoading: loadingHandler
    };

    return (
        <DataContext.Provider value={dataCtx}>{props.children}</DataContext.Provider>
    );
};

export default DataProvider;